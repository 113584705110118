.projectsContainer {
    background-color: var(--background-page);
    margin-top: 2em;
}

.projectsTitle {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: var(--text-color-highlight);
}

.clickLink {
    font-size: 1rem;
    margin-bottom: 2rem;
    color: var(--text-color-hover);
}

.projectItem {
    margin-bottom: 2rem;
}

.projectYear {
    font-size: 1.1rem;
    color: var(--text-color-hover);
    margin-bottom: 0.5rem;
}

.projectTitle {
    font-size: 1.3rem;
    color: var(--text-color-highlight);
    text-decoration: none;
}

.projectTitle:hover {
    text-decoration: underline;
}

.projectDescription {
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
}

.divider {
    border: none;
    height: 1px;
    background-color: var(--text-color);
    margin: 2rem 0;
}
