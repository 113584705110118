:root {
    --background-page: #fff;
    --text-color: #1b1c1e;
    --text-color-hover: #555;
    --text-color-highlight: #537d90;
    --letter-spacing: 0.1em;
}

* {
    font-family: "Amiri Quran", serif;
    letter-spacing: var(--letter-spacing);
    box-sizing: border-box;
}
