.masonryGrid {
    display: flex;
    margin: -1%; /* gutter size offset */
    width: auto;
  }
  
  .masonryGridColumn {
    padding-left: 0.25%; /* gutter size */
    background-clip: padding-box;
  }
  
  .imageWrapper {
    margin-bottom: 0.5%; /* gutter size */
    position: relative;
    overflow: hidden;
  }
  
  .image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .image:hover {
    cursor: pointer;
  }

  .imageWrapper:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    transition: background 0.3s ease-in-out;
  }
  