.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.fullImage {
  width: auto; /* Ensures the image scales with respect to height */
  height: auto; /* Ensures the image scales with respect to width */
  max-width: 100%; /* Ensures that landscape images fit within the modal width */
  max-height: 90vh; /* Ensures that portrait images fit within the modal height */
  border: 1px solid #ccc;
  object-fit: contain; /* Maintains the aspect ratio without cropping */
  object-position: center; /* Centers the image within the modal */
}
