.aboutContainer {
    text-align: center;
    padding: 0 25vw;
}

.aboutTitle {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: var(--text-color-highlight);
}

.aboutImage {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 0 auto 1rem;
}

.aboutText {
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1.6;
}

@media (max-width: 1200px) {
    .aboutContainer {
        padding: 0 10em;
    }
}

@media (max-width: 992px) {
    .aboutContainer {
        padding: 0 7em;
    }
}

@media (max-width: 768px) {
    .aboutContainer {
        padding: 0 4em;
    }
}

@media (max-width: 576px) {
    .aboutContainer {
        padding: 0 2em;
    }
}

@media (max-width: 400px) {
    .aboutContainer {
        padding: 0 1em;
    }
}