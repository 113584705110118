.homeContainer {
    padding: 0 15em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .homeContainer {
        padding: 0 10em;
    }
}

@media (max-width: 992px) {
    .homeContainer {
        padding: 0 7em;
    }
}

@media (max-width: 768px) {
    .homeContainer {
        padding: 0 4em;
    }
}

@media (max-width: 576px) {
    .homeContainer {
        padding: 0 2em;
    }
}

@media (max-width: 400px) {
    .homeContainer {
        padding: 0 1em;
    }
}
