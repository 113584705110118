.contactForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    gap: 1.5rem;
    padding: 2rem;
    background-color: var(--background-page);
}

.formGroup {
    display: flex;
    flex-direction: column;
}

.labelWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    margin-bottom: 0.5rem;
    color: var(--text-color-hover);
    font-size: 1rem;
}

.error {
    color: #ff6f61; 
    font-size: 0.875rem;
    margin-left: 1rem;
}

.inputField {
    background: var(--background-page);
    border: none;
    border-bottom: 1px solid var(--text-color-hover);
    color: var(--text-color);
    padding: 0.5rem 0;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
}

.inputField::placeholder {
    color: var(--text-color-hover);
}

.inputField:focus {
    border-bottom-color: var(--text-color-highlight);
}

.submitButton {
    background: none;
    border: none;
    border-bottom: 1px solid var(--text-color-hover);
    color: var(--text-color);
    padding: 0.5rem 0;
    font-size: 1rem;
    cursor: pointer;
    transition: border-color 0.3s ease;
    align-self: flex-start;
    text-transform: uppercase;
}

.submitButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.submitButton:hover:enabled {
    border-bottom-color: var(--text-color-highlight);
}

.responseMessage {
    color: var(--text-color-highlight);
    text-align: center;
    margin-top: 1rem;
}

@media (min-width: 768px) {
    .contactForm {
        width: 70%;
    }
}

@media (min-width: 1024px) {
    .contactForm {
        width: 60%;
    }
}
