.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 3em;
    background-color: var(--background-page);
    color: var(--text-color);
    position: relative;
}

.heading {
    margin-bottom: 3rem;
    font-size: 1.5rem;
    text-align: center;
    color: var(--text-color-highlight);
}

.followInstagram {
    position: absolute;
    bottom: 1rem;
    left: 4rem;
    color: var(--text-color);
}

.instagramLink {
    color: var(--text-color-highlight);
    text-decoration: none;
}
