.footerContainer {
    position: relative;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5em;
    flex-direction: column;
    background: var(--background-page);
    color: var(--text-color);
  }
  
  .footerText {
    margin: 0;
    text-align: center;
  }
  