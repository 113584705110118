.header {
  background: var(--background-page);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 15em;
}

.headerTitle {
  font-size: 2rem;
  color: var(--text-color);
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav {
  position: absolute;
  left: -1em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navList {
  margin-top: -3em;
}

.navItem {
  list-style: none;
  margin-bottom: 1.2em !important;
}

.navLink {
  color: var(--text-color);
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: var(--text-color-hover);
}

.navLinkActive {
  color: var(--text-color-highlight);
}

.socialIcons {
  display: flex;
  margin-top: 2em;
  margin-bottom: 1em;
}

.socialLink {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  transition: color 0.3s ease;
}

.socialLink:hover {
  color: var(--text-color-hover);
}

@media (max-width: 768px) {
  .nav {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: row;
    align-items: center;
  }

  .navList {
    display: flex;
    flex-direction: row;
    margin-top: 0;
  }

  .navItem {
    margin-bottom: 0;
    margin-right: 1.2em;
  }

  .socialIcons {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .header {
    height: 12em;
  }

  .headerTitle {
    font-size: 1.5rem;
  }

  .navLink {
    font-size: 1rem;
  }

  .socialLink {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .header {
    height: 10em;
  }

  .headerTitle {
    font-size: 1.2rem;
  }

  .navLink {
    font-size: 0.9rem;
  }

  .socialLink {
    font-size: 1rem;
  }
}
